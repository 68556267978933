<template>
  <svg viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3535 13.298L0.839629 25.7191L0.83963 0.876987L22.3535 13.298Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlay'
}
</script>
